import React from "react";
import { Layout, Title } from "../../components";
import { Axios } from "../../config";
import Photo1 from "../../assets/images/gite/1.webp";
import Photo2 from "../../assets/images/gite/2.webp";
import Photo3 from "../../assets/images/gite/3.webp";
import Photo4 from "../../assets/images/gite/4.webp";
import Photo5 from "../../assets/images/gite/5.webp";
import Photo6 from "../../assets/images/gite/6.webp";
import Photo7 from "../../assets/images/gite/7.webp";
import Photo8 from "../../assets/images/gite/8.webp";
import Photo9 from "../../assets/images/gite/9.webp";
import Photo10 from "../../assets/images/gite/street.jpeg";
import Photo11 from "../../assets/images/gite/11.webp";
import Photo12 from "../../assets/images/gite/12.webp";
import Photo13 from "../../assets/images/gite/chambre_1.jpg";
import Photo14 from "../../assets/images/gite/salon.jpeg";

const Photos = () => {
  const [images, setImages] = React.useState([
    {
      Image: {
        data: Photo1,
      },
    },
    {
      Image: {
        data: Photo2,
      },
    },
    {
      Image: {
        data: Photo3,
      },
    },
    {
      Image: {
        data: Photo4,
      },
    },
    {
      Image: {
        data: Photo5,
      },
    },
    {
      Image: {
        data: Photo14,
      },
    },
    {
      Image: {
        data: Photo6,
      },
    },
    {
      Image: {
        data: Photo7,
      },
    },
    {
      Image: {
        data: Photo8,
      },
    },
    {
      Image: {
        data: Photo13,
      },
    },
    {
      Image: {
        data: Photo9,
      },
    },
    {
      Image: {
        data: "",
      },
    },
    {
      Image: {
        data: Photo11,
      },
    },
    {
      Image: {
        data: Photo12,
      },
    },
  ]);
  const [loading, setLoading] = React.useState(true);

  // async function getPhotos() {
  //   return await Axios
  //     .get("/images")
  //     .then((response) => {
  //       setImages(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // React.useEffect(() => {
  //   getPhotos();
  // }, []);


  // if (loading) {
  //   return (
  //     <Layout>
  //       <div className="px-8">
  //         <Title />
  //         <div className="mt-4">
  //           <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-6">
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //             <div className="animate-pulse bg-gray-200 h-64"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </Layout>
  //   );
  // }

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="mt-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-6">
            {images.map((image) => (
              <div key={image.Id}>
                <img src={image.Image.data} alt={image.Name} className="w-full" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Photos;
