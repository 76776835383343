import React from "react";
import { ButtonIcon, TextContainer } from "../";
import { FiExternalLink } from "react-icons/fi";
import { Axios } from "../../config";
import Armand from "../../assets/images/activities/aven-armand.webp";
import Dargilan from "../../assets/images/activities/dargilan.webp";
import Loup from "../../assets/images/activities/loup.webp";
import Ferme from "../../assets/images/activities/ferme.webp";

const Visits = () => {
  const [images, setImages] = React.useState([
    {
      Image: {
        data: Armand,
      },
    },
    {
      Image: {
        data: Dargilan,
      },
    },
    {
      Image: {
        data: Loup,
      },
    },
    {
      Image: {
        data: Ferme,
      },
    },
  ]);
  const [loading, setLoading] = React.useState(true);

  // async function getImages() {
  //   try {
  //     const response = await Axios.get("/visite");
  //     setImages(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // React.useEffect(() => {
  //   getImages();
  // }, []);

  // if (loading) {
  //   return (
  //     <div className="mt-24">
  //       <div className="lg:flex relative">
  //         <div className="animate-pulse bg-gray-200 h-72 lg:w-2/4 lg:relative"></div>
  //         <div className="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-24 lg:top-8 mb-6 lg:mb-0 animate-pulse bg-gray-200 h-56"></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="mt-24 ">
      <div className="lg:h-96 lg:flex justify-center">
        <div className="lg:w-1/2">
          <img
            src={images[0].Image.data}
            alt="Aven Armand"
            className="lg:relative w-full left-2"
          />
        </div>
        <div className="lg:w-1/2 lg:relative lg:mt-0 mt-5 right-2 top-5">
          <TextContainer param="">
            <h2 className="text-2xl mb-4">L'Aven Armand</h2>
            <p>
              L’Aven Armand est un site classé exceptionnel. Découvrez sa
              fantastique forêt de 400 stalagmites, son immense salle
              souterraine, son entrée naturelle mystérieuse, sa visite illustrée
              d’ombres et de lumières, sa Spéléo Gare, son funiculaire, et le
              cadre unique de sa situation géographique sur les steppes du
              Causse Méjean.
            </p>
            <ButtonIcon
              params="mt-4"
              onClick={() => window.open("https://avenarmand.com/", "_blank")}
            >
              <div className="flex align-middle">
                <a className="mr-4">Visiter le site</a>
                <div className="relative">
                  <FiExternalLink className="absolute top-[10%] m-0" />
                </div>
              </div>
            </ButtonIcon>
          </TextContainer>
        </div>
      </div>
      <div className=" lg:h-96 lg:flex justify-center mt-20">
        <div className="lg:hidden">
          <img
            src={images[1].Image.data}
            alt="Dargilan"
            className="lg:relative w-full right-2"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 left-2 top-5 z-10">
            <h2 className="text-2xl mb-4">Dargilan</h2>
            <p>
              La Grotte de Dargilan située à Meyrueis près de Millau, au sud de
              la Lozère, captive par ses dimensions impressionnantes et par la
              variété de ses concrétions aux couleurs naturelles très
              accentuées. Ainsi appelée la grotte rose, la grotte de Dargilan
              permet une visite exceptionnelle dans un site classé au Patrimoine
              Mondial de l’UNESCO.
            </p>
            <ButtonIcon
              params="mt-4"
              onClick={() =>
                window.open("https://www.grotte-dargilan-48.com/", "_blank")
              }
            >
              <div className="flex align-middle">
                <a className="mr-4">Visiter le site</a>
                <div className="relative">
                  <FiExternalLink className="absolute top-[10%] m-0" />
                </div>
              </div>
            </ButtonIcon>
          </TextContainer>
        </div>
        <div className="lg:w-1/2">
          <img
            src={images[1].Image.data}
            alt="Dargilan"
            className="hidden lg:block relative w-full right-2"
          />
        </div>
      </div>
      <div className=" lg:h-96 lg:flex justify-center mt-20">
        <div className="lg:w-1/2">
          <img
            src={images[2].Image.data}
            alt="Loup du Gevaudan"
            className="lg:h-96 lg:relative lg:w-fit w-full left-4"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 right-4 top-5">
            <h2 className="text-2xl mb-4">Les loups du Gevaudan</h2>
            <p>
              Le Parc des Loups du Gévaudan est un site abritant des loups gris
              et des loups de l'arctique dans un espace naturel de plus de 50
              hectares, offrant un habitat proche de leur milieu naturel. Les
              visiteurs peuvent observer les loups à travers les clôtures, ou
              bien prendre part à des visites en forêt pour une expérience
              encore plus immersive. Le parc est également un lieu de
              sensibilisation et de protection de ces animaux.
            </p>
            <ButtonIcon
              params="mt-4"
              onClick={() =>
                window.open("https://www.loupsdugevaudan.com/", "_blank")
              }
            >
              <div className="flex align-middle">
                <a className="mr-4">Visiter le site</a>
                <div className="relative">
                  <FiExternalLink className="absolute top-[10%] m-0" />
                </div>
              </div>
            </ButtonIcon>
          </TextContainer>
        </div>
      </div>
      <div className="lg:h-96 lg:flex justify-center mt-20">
        <div className="lg:hidden">
          <img
            src={images[3].Image.data}
            alt="La Ferma Caussenarde"
            className="lg:hidden lg:relative right-2"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 left-2 top-5 z-10">
            <h2 className="text-2xl mb-4">La Ferme Caussenarde</h2>
            <p>
              Venir à la Ferme Caussenarde d’Autrefois, c’est faire un retour
              vers le passé, à une époque où le rythme et les priorités
              n’étaient pas les mêmes qu’aujourd’hui.
            </p>
            <ButtonIcon
              params="mt-4"
              onClick={() =>
                window.open("https://www.ferme-caussenarde.com/", "_blank")
              }
            >
              <div className="flex align-middle">
                <a className="mr-4">Visiter le site</a>
                <div className="relative">
                  <FiExternalLink className="absolute top-[10%] m-0" />
                </div>
              </div>
            </ButtonIcon>
          </TextContainer>
        </div>
        <div className="lg:w-1/2">
          <img
            src={images[3].Image.data}
            alt="La Ferma Caussenarde"
            className="hidden lg:block relative right-2"
          />
        </div>
      </div>

      {/* <div className="lg:flex relative ">
        <img
          src={images[0].Image.data}
          alt="Aven Armand"
          className="lg:w-2/4 lg:relative"
        />
        <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:relative lg:right-4 lg:top-8">
          <h2 className="text-2xl mb-4">L'Aven Armand</h2>
          <p>
            L’Aven Armand est un site classé exceptionnel. Découvrez sa
            fantastique forêt de 400 stalagmites, son immense salle souterraine,
            son entrée naturelle mystérieuse, sa visite illustrée d’ombres et de
            lumières, sa Spéléo Gare, son funiculaire, et le cadre unique de sa
            situation géographique sur les steppes du Causse Méjean.
          </p>
          <ButtonIcon params="mt-4" onClick={() => window.open("https://avenarmand.com/", "_blank")}>
            <div className="flex align-middle">
              <a className="mr-4">
                Visiter le site
              </a>
              <div className="relative">
                <FiExternalLink className="absolute top-[10%] m-0"/>
              </div>
            </div>
          </ButtonIcon>
        </TextContainer>
      </div>
      <div className="lg:flex relative mt-20 lg:h-[25rem] ">
        <img src={images[1].Image.data} alt="Dargilan" className="lg:hidden" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-3/6 lg:absolute lg:left-4 lg:top-7 z-10">
          <h2 className="text-2xl mb-4">Dargilan</h2>
          <p>
            La Grotte de Dargilan située à Meyrueis près de Millau, au sud de la
            Lozère, captive par ses dimensions impressionnantes et par la
            variété de ses concrétions aux couleurs naturelles très accentuées.
            Ainsi appelée la grotte rose, la grotte de Dargilan permet une
            visite exceptionnelle dans un site classé au Patrimoine Mondial de
            l’UNESCO. (Causses et Cévennes). Ce dédale souterrain, révélé en
            1888 par Edouard Alfred Martel et aménagé la première fois en 1890,
            fit de Dargilan la première grotte ouverte au public.
          </p>
          <ButtonIcon params="mt-4" onClick={() => window.open("https://www.grotte-dargilan-48.com/", "_blank")}>
            <div className="flex align-middle">
              <a  className="mr-4">
                Visiter le site
              </a>
              <div className="relative">
                <FiExternalLink className="absolute top-[10%] m-0"/>
              </div>
            </div>
          </ButtonIcon>
        </TextContainer>
        <img
          src={images[1].Image.data}
          alt="Dargilan"
          className="hidden lg:block lg:w-2/4 lg:absolute lg:right-0"
        />
      </div>
      <div className="lg:flex relative mt-14 lg:h-96 ">
        <img src={images[2].Image.data} alt="Loup du Gevaudan" className="w-full lg:w-2/4" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-24 lg:top-7">
          <h2 className="text-2xl mb-4">Les loups du Gevaudan</h2>
          <p>
            Le Parc des Loups du Gévaudan est un site abritant des loups gris et
            des loups de l'arctique dans un espace naturel de plus de 50
            hectares, offrant un habitat proche de leur milieu naturel. Les
            visiteurs peuvent observer les loups à travers les clôtures, ou bien
            prendre part à des visites en forêt pour une expérience encore plus
            immersive. Le parc est également un lieu de sensibilisation et de
            protection de ces animaux.
          </p>
          <ButtonIcon params="mt-4" onClick={() => window.open("https://www.loupsdugevaudan.com/", "_blank")}>
            <div className="flex align-middle">
              <a className="mr-4">
                Visiter le site
              </a>
              <div className="relative">
                <FiExternalLink className="absolute top-[10%] m-0"/>
              </div>
            </div>
          </ButtonIcon>
        </TextContainer>
      </div>
      <div className="lg:flex relative mt-20 lg:h-[27rem]">
        <img src={images[3].Image.data} alt="La Ferma Caussenarde" className="lg:hidden" />
        <TextContainer param="mt-4 mb-6 lg:mb-0 lg:mt-0 lg:w-2/5 lg:absolute lg:left-4 lg:top-8 z-10">
          <h2 className="text-2xl mb-4">La Ferme Caussenarde</h2>
          <p>
            Venir à la Ferme Caussenarde d’Autrefois, c’est faire un retour vers
            le passé, à une époque où le rythme et les priorités n’étaient pas
            les mêmes qu’aujourd’hui.
          </p>
          <ButtonIcon params="mt-4" onClick={() => window.open("https://www.ferme-caussenarde.com/", "_blank")}>
            <div className="flex align-middle">
              <a className="mr-4">
                Visiter le site
              </a>
              <div className="relative">
                <FiExternalLink className="absolute top-[10%] m-0"/>
              </div>
            </div>
          </ButtonIcon>
        </TextContainer>
        <img
          src={images[3].Image.data}
          alt="La Ferma Caussenarde"
          className="hidden lg:block lg:h-5/6 lg:absolute lg:right-0"
        />
      </div> */}
    </div>
  );
};

export default Visits;
