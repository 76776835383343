import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Gite,
  Activities,
  Photos,
  Booking,
  Contact,
  Login,
  Infos,
  Admin,
} from "./pages";
import { Menu, Navbar } from "./components";
function App() {
  return (
    <div>
      <BrowserRouter>
        <div className="lg:hidden">
          <Navbar />
        </div>
        <div className="hidden lg:block">
          <Menu />
        </div>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/index.html" component={<Home />} />
            <Route path="/gite" element={<Gite />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/infos" element={<Infos />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
