import React from "react";
import { useState } from "react";
import "./toggleButton.css";

const ToggleButton = ({
  text1,
  text2,
  component1: Component1,
  component2: Component2,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  return (
    <div className="mt-12">
      <div className="w-fit flex space-x-4 mx-auto" onClick={() => setIsChecked(!isChecked)}>
        <span
          className={
            isChecked
              ? "px-2 border-b-2 border-[#bfad7f] text-[#bfad7f] pb-1 cursor-pointer"
              : "px-2 border-transparent text-[#909090] cursor-pointer pb-1 transition duration-300 ease-in-out hover:border-[#C0C0C0] hover:border-b-2"
          }
        >
          {text1}
        </span>
        <span
          className={
            !isChecked
              ? "px-2 border-b-2 border-[#bfad7f] text-[#bfad7f] pb-1 cursor-pointer"
              : "px-2 border-transparent text-[#909090] cursor-pointer pb-1 transition duration-300 ease-in-out hover:border-[#C0C0C0] hover:border-b-2"
          }
        >
          {text2}
        </span>
      </div>
      {isChecked ? <Component1 /> : <Component2 />}
    </div>
  );
};

export default ToggleButton;
