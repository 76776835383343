import React from "react";

const Title = () => {
  return (
    <h1 className="text-3xl text-[#909090] text-center mb-16">
      Gîte Chrisabel dans les Gorges du Tarn
    </h1>
  );
};

export default Title;
