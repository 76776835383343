import React from "react";
import {
  Layout,
  Title,
  TextContainer,
  DisplayPricePeriode,
  Button,
} from "../../components";
import { useNavigate } from "react-router-dom";
// import Calendar from 'react-calendar'
import "react-calendar/dist/Calendar.css";
import Photo from "../../assets/images/gite/booking_img.jpg";
const Booking = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="lg:relative flex">
          <img src={Photo} className="lg:w-2/3 relative top-10 -left-16" />
          <TextContainer param="mt-4 lg:mt-0 lg:w-fit lg:absolute -right-16 top-0">
            <div className="">
              <div>
                <h2 className="mb-2 text-2xl">Réservation</h2>
                <DisplayPricePeriode />
                <p className="mb-4">Week-end: à convenir</p>
              </div>
              <div>
                <h2 className="mb-2 text-2xl">Les disponibilités</h2>
                <div className="mb-6 text-black mt-2">
                  <iframe
                    src="https://calendar.google.com/calendar/embed?src=scichrisabel%40gmail.com&ctz=Europe%2FParis&bgcolor=%23BFAD7F&ctz=Europe%2FParis&showTz=0&showCalendars=0&showTabs=0&showPrint=0&showDate=1&showNav=1&showTitle=0"
                    style={{ border: "0", height: "15rem" }}
                    width="100%"
                  ></iframe>
                </div>
              </div>
            </div>
            <Button text="Réserver" onClick={() => navigate("/contact")} />
          </TextContainer>
          {/* <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-0 lg:top-8 mb-8">
            <h2 className="mb-4 text-2xl">Réservation</h2>
            <DisplayPricePeriode />
            <p className="mb-6">Week-end: à convenir</p>
            <h2 className="mb-4 text-2xl">Les disponibilités</h2>
            <div className="mb-6 text-black">
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23A79B8E&ctz=Europe%2FParis&showTz=0&showCalendars=0&showTabs=0&showPrint=0&showDate=1&showNav=1&showTitle=0&src=c2NpY2hyaXNhYmVsQGdtYWlsLmNvbQ&color=%23AD1457"
                style={{ border: "0" }}
                width="100%"
                height="200"
              ></iframe>
            </div>
            <Button text="Réserver" onClick={() => navigate("/contact")} />
          </TextContainer> */}
        </div>
      </div>
    </Layout>
  );
};

export default Booking;
