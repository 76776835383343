import React from "react";
import { Button, Layout, TextContainer, Title } from "../../components";
import { Axios } from "../../config";

const Infos = () => {

  const handleDownloadPdf = () => {
    Axios.get("/pdf", { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "gite-chrisabel.pdf";
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleButtonClick = () => {
    handleDownloadPdf();
  };

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="flex align-center justify-center">
          <TextContainer param="w-64 h-64 relative">
            <h2 className="mb-4 text-2xl">La vie dans le gîte</h2>
            <p>
              Quelques consignes et informations pour votre séjour dans le gîte
            </p>
            <div className="absolute bottom-4">
              <Button text="PDF" onClick={handleButtonClick} />
            </div>
          </TextContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Infos;
