import React from "react";
import { useState } from "react";
import { Axios } from "../../config";
import { TextContainer } from "../";

const LoginForm = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function login() {
    return await Axios.get("/login", {
      params: {
        username: username,
        password: password,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          handleLogin();
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    login();
  }

  return (
    <div className="px-8">
      <div className="flex align-center justify-center">
        <TextContainer param="w-64 h-64 relative">
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full rounded-full py-1 pl-3 text-black mb-4"
              />
            </label>
            {/* <br /> */}
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-full py-1 pl-3 text-black"
              />
            </label>
            <br />
            <button
              type="submit"
              className="absolute bottom-4 right-4 px-6 py-1 bg-[#fff] text-[#909090] rounded-full border-2 border-transparent hover:border-2 border-[#fff] hover:bg-[#bfad7f] hover:text-[#fff] transition duration-300 ease-in-out"
            >
              Log In
            </button>
          </form>
        </TextContainer>
      </div>
    </div>
  );
};

export default LoginForm;
