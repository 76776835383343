export const SidebarData = [
    {
      title: 'Accueil',
      path: '/',
      cName: 'nav-text'
    },
    {
      title: 'Le Gîte',
      path: '/gite',
      cName: 'nav-text'
    },
    {
      title: 'Les activités',
      path: '/activities',
      cName: 'nav-text'
    },
    {
      title: 'Les Prix',
      path: '/booking',
      cName: 'nav-text'
    },
    {
      title: 'Photos',
      path: '/photos',
      cName: 'nav-text'
    },
    {
      title: 'Nous contacter',
      path: '/contact',
      cName: 'nav-text'
    },
    {
        title: 'Vie dans le gite',
        path: '/infos',
        cName: 'nav-text'
      }
  ];