import React from "react";
import { Layout, LoginForm } from "../../components";
import Admin from "../admin/Admin";
import { useState } from "react";
import { Title } from "../../components";
import { useNavigate } from "react-router-dom";


const Login = () => {
  const navigate = useNavigate();

  function handleLogin() {
    navigate("/admin");
  }

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <LoginForm handleLogin={handleLogin} />
      </div>
    </Layout>
  );
};

export default Login;
