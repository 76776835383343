import React, {useState, useEffect} from "react";
import axios from "axios";
import { Axios } from "../../config";

const DisplayPricePeriode = () => {
  const [pricePeriode, setPricePeriode] = useState([]);

  async function getPricePeriode() {
    return await Axios
      .get("priceperiodes")
      .then((res) => {
        setPricePeriode(res.data);
      })
      .catch((err) => {
        console.log("here", err);
      });
  }

  useEffect(() => {
    getPricePeriode();
  }, []);

  return (
    <div>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="p-2">Période</th>
            <th className="p-2">Prix à la semaine</th>
          </tr>
          {pricePeriode.map((element) => {
            return (
              <tr key={element.id}>
                <td className="boder-white border py-1 px-2 text-center">{element.name}</td>
                <td className="boder-white border text-center">{element.price}€</td>
              </tr>
            );  
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayPricePeriode;
