import React from "react";
import { Layout, Title, ToggleButton, Visits, Sport } from "../../components";


const Activities = () => {
  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="lg:relative">
          <div className="text-center">
            <p className="text-[#909090]">
              Les Gorges du Tarn offrent un décor à couper le souffle pour tous
              les amoureux de la nature et les amateurs d'aventure. Les
              activités y sont nombreuses et variées : randonnée, escalade,
              canoë-kayak, via ferrata, visite des grottes. baignade dans les
              rivières cristallines, observation de la faune et de la flore. Le
              patrimoine culturel est également riche avec des villages perchés
              typiques à découvrir.
            </p>
          </div>
          <div className="justify-center align-center">
            <ToggleButton text1="Visites" text2="Sport" component1={Visits} component2={Sport}/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Activities;
