import React, { useEffect, useState } from "react";
import axios from "axios";
import { Axios } from "../../config";
import AddPricePeriode from "./AddPricePeriode";
import { FiX } from "react-icons/fi";

const GetPricePeriode = () => {
  const [pricePeriode, setPricePeriode] = useState([]);

  async function getPricePeriode() {
    return await Axios.get("/priceperiodes")
      .then((res) => {
        setPricePeriode(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getPricePeriode();
  }, []);

  async function deletePricePeriode(id) {
    return await Axios.delete(`priceperiodes/${id}`)
      .then((res) => {
        getPricePeriode();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className="border-b-2 lg:w-1/2 pb-4">
        <h2 className="mb-8 text-xl">Prix</h2>
        {pricePeriode.map((element, index) => {
          return (
            <div
              key={index}
              className="flex flex-wrap w-full border border-[#bfad7f] rounded-full mb-2 py-4 px-6 relative"
            >
              <p className="mr-4 w-min-fit w-56">{element.name}</p>
              <p className="mr-4">{element.price}€</p>
              <button
                className="absolute right-6 bg-red-300 hover:bg-red-500 rounded-full p-2 my-auto bottom-3"
                onClick={() => deletePricePeriode(element.id)}
              >
                <FiX />
              </button>
            </div>
          );
        })}
      </div>
      <div>
        <AddPricePeriode getPricePeriode={getPricePeriode} />
      </div>
    </div>
  );
};

export default GetPricePeriode;
