import React from 'react';

const Layout = ({ children }) => {
  return (
    <div className="flex items-center justify-center mb-8">
      <div className="md:w-11/12 sm:w-11/12 w-full max-w-[875px]">{children}</div>
    </div>
  );
};

export default Layout;