import axios from 'axios'

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    //
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const API_URI = isLocalhost ? 'http://localhost:3001/api/' : 'https://api.gite-chrisabel.fr/api/';

export const Axios = axios.create({
    baseURL: API_URI,
});