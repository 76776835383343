import React from "react";
import {
  Button,
  IconGrid,
  Layout,
  TextContainer,
  Title,
} from "../../components";
import Image from "../../assets/images/gite/gite_img.webp";
import { useNavigate } from "react-router-dom";

const Gite = () => {
  const navigate = useNavigate();
  // const [image, setImage] = React.useState(null);
  // const [base64String, setBase64String] = React.useState("");
  // const [loading, setLoading] = React.useState(true);

  // async function getPhoto() {
  //   return await Axios.get("/images/34")
  //     .then((response) => {
  //       setImage(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // React.useEffect(() => {
  //   getPhoto();
  // }, []);

  // if (loading) {
  //   return (
  //     <Layout>
  //       <div className="px-8">
  //         <Title />
  //         <div className="lg:relative">
  //           <div className="animate-pulse bg-gray-200 h-[36rem] lg:w-2/4 lg:ml-14"></div>
  //           <div className="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-14 lg:top-12 mb-6 lg:mb-0 animate-pulse bg-gray-200 h-[36rem]"></div>
  //         </div>
  //       </div>
  //     </Layout>
  //   );
  // }

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="lg:relative">
          <img src={Image} alt="Gite" className="lg:w-2/4 lg:ml-14"/>
          <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-14 lg:top-12 mb-6 lg:mb-0">
            <h2 className="mb-4 text-2xl">Le gîte</h2>
            <p className="mb-1">Le gîte est disposé sur deux étages :</p>
            <p className="mb-2">
              - RCH : salon-salle à manger, cuisine équipée (four, plaque,
              lave-vaisselle…), toilettes séparées et buanderie avec lave-linge.
            </p>
            <p className="mb-2">
              - 1er étage : 3 chambres à coucher, dont deux avec un lit de
              160x200 et une avec deux lits de 100x190, salle-de-bains avec
              douche et toilette.
            </p>
            <p className="mb-4">
              - Extérieur : terrasse meublée sous tonnelle adossée en contrebas,
              barbecue.
            </p>
            <p className="mb-4">
              Nous ne sommes malheureusement pas encore en mesure de vous
              proposer la télévision.
            </p>
            <p className="mb-4">Draps de lit et de bain non fournis.</p>
            <IconGrid />
            <Button text="Photos" onClick={() => navigate("/photos")} />
          </TextContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Gite;
