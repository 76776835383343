import React from "react";
import { Button, Layout, TextContainer, Title } from "../../components";
import Image from "../../assets/images/gite/img_home.webp";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="lg:relative">
          <img src={Image} alt="Gorge du Tarn" className="lg:w-2/3"/>
          <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-0 lg:top-16 lg:mb-0">
            <h2 className="mb-4 text-2xl">
              Évadez-vous dans les Gorges du Tarn
            </h2>
            <p className="mb-4">
              Blajoux, dans ce hameau situé au bord du Tarn et son écrin de
              verdure, nous louons un gîte pour 6 personnes. Situé à la fois au
              centre du village (boulangerie, fromagerie, bar-café) et à
              quelques 300 mètres du Tarn, ce logement fraichement rénové sera
              le point de départ idéal pour toutes vos activités : baignades,
              canoë-kayak, randonnée, vélo, canyoning, via ferrata.
            </p>
            <p className="mb-4">
              Au-delà des activités sportives, une multitude de visites vous
              invitent à découvrir les gorges et son environnement. Classés au
              patrimoine mondial de l’UNESCO depuis 2011, les Causses et les
              Cévennes ne manqueront pas de vous enchanter.
            </p>
            <Button text="Le gîte" onClick={() => navigate("/gite")} />
          </TextContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
