import React from "react";

const Button = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="px-6 py-1 bg-[#fff] text-[#909090] rounded-full border-2 border-transparent hover:border-2 border-[#fff] hover:bg-[#bfad7f] hover:text-[#fff] transition duration-300 ease-in-out"
    >
      {text}
    </button>
  );
};

export default Button;
