import React, { useState } from 'react'
import { HiMenu, HiX } from 'react-icons/hi'
import { SidebarData } from './SidebarData';
import { useNavigate } from "react-router-dom";
import './navbar.css';

const Navbar = () => {

    const navigate = useNavigate();
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    function handleClick(path) {
        navigate(path);
        showSidebar();
    }

  return (
    <div>
        <div className='flex justify-end my-8 mr-8'>
            <HiMenu size={28} onClick={showSidebar} className='cursor-pointer fill-[#909090]'/>
        </div>
        <nav className={sidebar ? 'nav-menu active shadow-2xl z-50' : 'nav-menu z-50'}>
            <ul className='w-full' >
                <li className='flex justify-end my-8 mr-8' onClick={showSidebar}>
                    <HiX size={28} color={'#fafafa'} className='cursor-pointer'/>
                </li>
                {SidebarData.map((item, index) => {
                return (
                        <li key={index} className={'hover:bg-[#909090] cursor-pointer py-2 pl-4 h-12 flex justify-start items-center border-t'} onClick={() => handleClick(item.path)}>
                            <span className='text-[#fafafa] ml-4'>{item.title}</span>
                        </li>
                    );
                })}
            </ul>
        </nav>
    </div>
  )
}

export default Navbar