import React from "react";

const Oauth = () => {

//   const client = new OAuth2Client({
//     clientId: "1014103375261-libp6705j00d5hdfqiknm94bfdtr42hu.apps.googleusercontent.com",
//     clientSecret: "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",
//     redirectUri: "https://gite-chrisabel.fr", // Replace with your app's redirect URI
//   });

  return (
    <div>
      <button onClick={handleAuthClick}>Authorize</button>
    </div>
  );
};

export default Oauth;
