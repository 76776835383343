// import axios from "axios";
import { Axios } from "../../config";
import React from "react";

const AddPricePeriode = ({ getPricePeriode }) => {
  // function to submit the form data to the server

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const price = e.target.price.value;
    e.target.reset();

    Axios.get("/priceperiode", {
      params: {
        name: name,
        price: price,
      },
    })
      .then((res) => {
        getPricePeriode();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="mt-8 lg:w-1/2">
      <h2 className="mb-8 text-xl">Ajouter un prix</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="flex mb-4">
          <label htmlFor="name" className="w-1/6 py-1">
            Nom
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="pl-3 py-1 rounded-full border"
            required
          />
        </div>
        <div className="flex mb-6">
          <label htmlFor="price" className="w-1/6 py-1">
            Prix
          </label>
          <input
            type="number"
            name="price"
            id="price"
            className="pl-3 py-1 rounded-full mr-1 border"
            required
          />
          <p className="py-1">€</p>
        </div>
        <button
          type="submit"
          className="bg-green-300 hover:bg-green-500 px-4 py-1 rounded-full"
        >
          Ajouter
        </button>
      </form>
    </div>
  );
};

export default AddPricePeriode;
