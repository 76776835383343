import React from "react";
import { Layout, Title, TextContainer } from "../../components";
import Photo from "../../assets/images/gite/contact_img.jpg";
const Contact = () => {
  return (
    <Layout>
      <div className="px-8">
        <Title />
        <div className="lg:relative">
          <img src={Photo} className="lg:w-2/3"/>
            <TextContainer param="mt-4 lg:mt-0 lg:w-64 lg:h-64 lg:absolute lg:right-10 lg:top-16">
                <h2 className="mb-4 text-2xl">Contact</h2>
                <p>Vous pouvez nous contacter à tout moment par mail ou par téléphone</p>
                <p className="mt-4 mb-4 font-medium border border-white rounded-full px-3 py-1 w-fit">kleiber.christian@orange.fr</p>
                <p className="mt-2 font-medium border border-white rounded-full px-3 py-1 w-fit">06 80 22 67 24</p>
            </TextContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
