import React from "react";
import Layout from "../layout/Layout";
import { SidebarData } from "./SidebarData";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Menu = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Layout>
      <ul className="flex justify-between w-[875px] my-8">
        {SidebarData.map((item, index) => {
          let className =
            "italic border-t-2 border-transparent hover:border-[#C4C4C4] text-[#909090] pt-1 cursor-pointer";
          if (index === activeIndex) {
            className += " active";
          }
          return (
            <li
              key={index}
              onClick={() => {
                setActiveIndex(index);
                navigate(item.path);
              }}
              className={className}
            >
              {item.title}
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export default Menu;
