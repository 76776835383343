import React from 'react'
import { IconData } from './IconData'

const IconGrid = () => {
  return (
    <div className='flex mb-4 flex-wrap'>
        {IconData.map((item, index) => {
            return (
                <div key={index} className="flex flex-row mr-4">
                    <div className='mr-2'>{item.icon}</div>
                    <p>{item.title}</p>
                </div>
            )
        })}
    </div>
  )
}

export default IconGrid