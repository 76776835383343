import React from "react";
import { TextContainer } from "../";
import { Axios } from "../../config";
import Elastique from "../../assets/images/activities/107.webp";
import Canoe from "../../assets/images/activities/canoe.webp";
import Canyoning from "../../assets/images/activities/canyoning.webp";
import randone from "../../assets/images/activities/randone.webp";

const Sport = () => {
  const [images, setImages] = React.useState([
    {
      Image: {
        data: Canoe,
      },
    },
    {
      Image: {
        data: Canyoning,
      },
    },
    {
      Image: {
        data: Elastique,
      },
    },
    {
      Image: {
        data: randone,
      },
    },
  ]);
  const [loading, setLoading] = React.useState(true);

  // async function getImages() {
  //   try {
  //     const response = await Axios.get("/sport");
  //     setImages(response.data);
  //     console.log(images);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // React.useEffect(() => {
  //   getImages();
  // }, []);

  // if (loading) {
  //   return (
  //     <div className="mt-24">
  //       <div className="lg:flex relative">
  //         <div className="animate-pulse bg-gray-200 h-72 lg:w-2/4 lg:relative"></div>
  //         <div className="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-24 lg:top-8 mb-6 lg:mb-0 animate-pulse bg-gray-200 h-56"></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="mt-24">
      <div className="lg:h-80 lg:flex justify-center">
        <div className="lg:w-1/2">
          <img
            src={images[0].Image.data}
            alt="Aven Armand"
            className="lg:relative w-full left-2"
          />
        </div>
        <div className="lg:w-1/2 ">
          <TextContainer param="lg:relative lg:mt-0 mt-5 right-2 top-5">
            <h2 className="text-2xl mb-4">Canoë-Kayak</h2>
            <p>
              La descente du Tarn en canoë peut se faire d’avril à octobre,
              selon le niveau de l’eau. C’est une rivière accessible à tous. Les
              loueurs vous proposent différents parcours, qui vous permettront
              d’apprécier les gorges à votre rythme.
            </p>
          </TextContainer>
        </div>
      </div>
      <div className=" lg:h-80 lg:flex justify-center mt-20">
        <div className="lg:hidden">
          <img
            src={images[1].Image.data}
            alt="Dargilan"
            className=" w-full right-2"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 left-2 top-5 z-10">
            <h2 className="text-2xl mb-4">Canyoning</h2>
            <p>
              Le canyoning, ce sport en eaux vives est l’occasion de vous
              défouler et vous déconnecter. Vous partez parcourir à pied les
              gorges, descendre des rivières, cascades et vallons et glisser sur
              des toboggans de roche polie.
            </p>
          </TextContainer>
        </div>
        <div className="w-1/2">
          <img
            src={images[1].Image.data}
            alt="Dargilan"
            className="hidden lg:block relative w-full right-2"
          />
        </div>
      </div>
      <div className=" lg:h-80 lg:flex justify-center mt-20">
        <div className="lg:w-1/2">
          <img
            src={images[2].Image.data}
            alt="Loup du Gevaudan"
            className="w-full lg:relative left-2"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 right-2 top-5">
            <h2 className="text-2xl mb-4">Saut a l'élastique</h2>
            <p>
              Le 107, c’est le saut à l’élastique le plus haut du sud de la
              France et on peut vous garantir que vous allez vivre une
              expérience extrêmement unique. Du haut de cette falaise des Gorges
              du Tarn, c’est juste vous face à vous-même !
            </p>
          </TextContainer>
        </div>
      </div>
      <div className=" lg:h-80 lg:flex justify-center mt-20">
        <div className="lg:hidden lg:w-1/2">
          <img
            src={images[3].Image.data}
            alt="La Ferma Caussenarde"
            className="lg:hidden lg:relative right-2"
          />
        </div>
        <div className="lg:w-1/2">
          <TextContainer param="lg:relative lg:mt-0 mt-5 left-2 top-5 z-10">
            <h2 className="text-2xl mb-4">Randonné</h2>
            <p>
              La randonnée dans les gorges du Tarn offre des paysages à couper
              le souffle et une expérience de nature sauvage unique. Les
              falaises de calcaire, les rivières cristallines et les forêts
              luxuriantes créent un décor idyllique pour une aventure en plein
              air.
            </p>
          </TextContainer>
        </div>
        <div className="w-1/2">
          <img
            src={images[3].Image.data}
            alt="La Ferma Caussenarde"
            className="hidden lg:block relative right-2"
          />
        </div>
      </div>

      {/* <div className="lg:flex relative">
        <img src={images[0].Image.data} alt="Canoë" className="lg:w-3/5" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:right-4 lg:top-8">
          <h2 className="text-2xl mb-4">Canoë-Kayak</h2>
          <p>
            La descente du Tarn en canoë peut se faire d’avril à octobre, selon
            le niveau de l’eau. C’est une rivière accessible à tous. Les loueurs
            vous proposent différents parcours, qui vous permettront d’apprécier
            les gorges à votre rythme.
          </p>
        </TextContainer>
      </div>
      <div className="lg:flex relative mt-12 lg:h-80">
        <img src={images[1].Image.data} alt="Canyoning" className="lg:hidden" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:left-6 lg:top-8 z-10">
          <h2 className="text-2xl mb-4">Canyoning</h2>
          <p>
            Le canyoning, ce sport en eaux
            vives est l’occasion de vous défouler et vous déconnecter. Vous
            partez parcourir à pied les gorges, descendre des rivières, cascades
            et vallons et glisser sur des toboggans de roche polie.
          </p>
        </TextContainer>
        <img
          src={images[1].Image.data}
          alt="Canyoning"
          className="hidden lg:block lg:w-7/12 lg:absolute lg:right-0"
        />
      </div>
      <div className="lg:flex relative mt-12">
        <img src={images[2].Image.data} alt="107" className="lg:w-3/5" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-5/12 lg:absolute lg:right-0 lg:top-8">
          <h2 className="text-2xl mb-4">Saut a l'élastique</h2>
          <p>
            Le 107, c’est le saut à
            l’élastique le plus haut du sud de la France et on peut vous
            garantir que vous allez vivre une expérience extrêmement unique. Du
            haut de cette falaise des Gorges du Tarn, c’est juste vous face à
            vous-même !
          </p>
        </TextContainer>
      </div>
      <div className="lg:flex relative mt-12 lg:h-96">
      <img src={images[3].Image.data} alt="Randonnée" className="lg:hidden" />
        <TextContainer param="mt-4 lg:mt-0 lg:w-2/5 lg:absolute lg:left-6 lg:top-8 z-10 mb-6 lg:mb-0">
          <h2 className="text-2xl mb-4">Randonné</h2>
          <p>
            La randonnée dans les gorges du Tarn offre des paysages à couper le
            souffle et une expérience de nature sauvage unique. Les falaises de
            calcaire, les rivières cristallines et les forêts luxuriantes créent
            un décor idyllique pour une aventure en plein air.
          </p>
        </TextContainer>
        <img src={images[3].Image.data} alt="Randonnée" className="hidden lg:block lg:w-7/12 lg:absolute lg:right-0" />
      </div> */}
    </div>
  );
};

export default Sport;
