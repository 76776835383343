import React from "react";
import { GetPricePeriode, Layout, Title } from "../../components";
import { useState } from "react";
import { Axios } from "../../config";
import Oauth from '../../components/oauth/Oauth';

const Admin = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  async function getAutorization() {
    const token = localStorage.getItem("token");
    const response = await Axios.get("/protected", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setData(response.data);
        setError("");
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  React.useEffect(() => {
    getAutorization();
  }, []);

  return (
    <Layout>
      <div className="px-8">
        <Title />
        {!data ? null : <GetPricePeriode />}
        <Oauth />
      </div>
    </Layout>
  );
};

export default Admin;
