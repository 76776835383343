import React from "react";

const TextContainer = ({children, param}) => {
  return (
    <div className={`bg-[#bfad7f] p-4 text-[#fff] rounded shadow-xl ${param}`}>
        {children}
    </div>
  );
};

export default TextContainer;
